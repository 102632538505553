$(document).ready(function ()
{
    showcase.init();

    $('.navbar-nav li a').click(function (e)
    {
        e.preventDefault();

        var element = $($(this).attr('href'));

        $('html, body').animate(
            {
                scrollTop: element.offset().top
            }, 1200, 'easeOutCubic');
    });

    $("#contact-form").bootstrapValidator({
        live: 'enabled',
        submitButtons: 'input[type="submit"]',
        fields: {
            name: {
                group: 'td',
                validators: {
                    notEmpty: {
                        message: 'Uw naam is verplicht om in te vullen'
                    }
                }
            },
            email: {
                group: 'td',
                validators: {
                    notEmpty: {
                        message: 'Uw e-mailadres is verplicht om in te vullen'
                    },
                    emailAddress: {
                        message: 'Het ingevulde e-mailadres is niet geldig'
                    }
                }
            },
            subject: {
                group: 'td',
                validators: {
                    notEmpty: {
                        message: 'Het is verplicht om een onderwerp in te vullen'
                    }
                }
            },
            message: {
                group: 'th',
                validators: {
                    notEmpty: {
                        message: 'Het is verplicht om een bericht te schrijven'
                    }
                }
            }
        }
    })
        .on('success.form.bv', function(e) {

            e.preventDefault();

            var $form = $(e.target);
            var bv = $form.data('bootstrapValidator');

            $.post($form.attr('action'), $form.serialize(), function(result) {


            })
                .fail(function () {
                    $('.send th').text('Er is iets fout gegaan, probeer het later opnieuw.');
                })
                .always(function () {
                    $('.send').removeClass('hidden');
                });
        });
});

$(window).load(function ()
{
    $('#project-overview').isotope(
    {
        filter: '*',
        itemSelector: '.item',
        animationEngine: 'best-available',
        animationOptions: {
            duration: 800
        },
        containerStyle: {
            position: 'relative',
            overflow: 'visible'
        }
    });
});