var showcase = {

    container: '#project-overview',
    selector: '.item',
    showcaseContainer: '#showcase',
    projectContainer: '#project',

    fixedHeader: '.fixed-header',
    projectName: '.project-name',
    closeBtn: '.project-close',
    prevBtn: '.project-prev',
    nextBtn: '.project-next',

    banner: '.banner',
    headerBtnMobile: '.navbar-default',

    bannerHeight: 760,

    projectOpen: false,
    closeBtnBlack: false,
    closeBtnBlackColor: '#959595',

    init: function ()
    {
        var _this = this;
        var container = $(this.projectContainer);
        var item = $(_this.selector, _this.container);

        item.click(function ()
        {
            _this.load($(this).attr('data-project'));
        });


        this.loadCloseBtn();
        this.loadPrevBtn();
        this.loadNextBtn();

        $(window).on('scroll', function()
        {
            _this.checkScroll();
        });
    },

    loadCloseBtn: function ()
    {
        var _this = this;

        $(document).on('click', _this.closeBtn, function (e)
        {
            e.stopPropagation();
            e.preventDefault();

            _this.scrollTo($(_this.showcaseContainer).offset().top - 150, 700);

            setTimeout(function () {
                $(_this.projectContainer).empty();
            }, 700);
        });
    },

    loadPrevBtn: function ()
    {
        var _this = this;

        $(document).on('click', _this.prevBtn, function (e)
        {
            e.stopPropagation();
            e.preventDefault();

            var prevProject = _this.getPrevProject();
            _this.load(prevProject, true);
        });
    },

    getPrevProject: function ()
    {
        var project = $(this.projectContainer).attr('data-project');
        var showcaseProject = $(this.selector + "[data-project='" + project + "']", this.showcaseContainer).prev();

        if (showcaseProject.length == 0)
        {
            showcaseProject = $(this.selector, this.showcaseContainer).last();
        }

        return showcaseProject.attr('data-project');
    },

    loadNextBtn: function ()
    {
        var _this = this;

        $(document).on('click', _this.nextBtn, function (e)
        {
            e.stopPropagation();
            e.preventDefault();

            var nextProject = _this.getNextProject();
            _this.load(nextProject, true);
        });
    },

    getNextProject: function ()
    {
        var project = $(this.projectContainer).attr('data-project');
        var showcaseProject = $(this.selector + "[data-project='" + project + "']", this.showcaseContainer).next();

        if (showcaseProject.length == 0)
        {
            showcaseProject = $(this.selector, this.showcaseContainer).first();
        }

        return showcaseProject.attr('data-project');
    },

    load: function (project, showTopBar)
    {
        var _this = this;
        var container = $(_this.projectContainer);

        loader.show();

        container.attr('data-project', project);

        container.load('project/' + project, function ()
        {
            _this.bannerHeight = $(_this.banner, container).height();

            setTimeout(function ()
            {
                _this.scrollTo(container, 700);

                setTimeout(function ()
                {
                    loader.hide();

                    if (showTopBar)
                    {
                        _this.changeTopBar(false);
                        _this.openTopBar(true);
                    }
                }, 500);
            }, 700);

        });
    },

    scrollTo: function (scrollTo, speed)
    {
        $('html, body').animate(
        {
            scrollTop: ((scrollTo instanceof jQuery) ? scrollTo.offset().top : scrollTo)
        }, speed, 'easeOutCubic');
    },

    checkScroll: function ()
    {
        var current = $(document).scrollTop();
        var top = $(this.projectContainer).offset().top;
        var bottom = $('#about').offset().top;

        var offset = 40;

        if ((top - offset) < current && (bottom - 200 + offset) > current) {
            if (this.projectOpen == false)
            {
                this.openTopBar(true);
            }
        } else
        {
            if (this.projectOpen == true)
            {
                this.openTopBar(false);
            }
        }

        if (this.projectOpen == true)
        {
            if ((top + this.bannerHeight - offset) > current)
            {
                this.changeTopBar(false);
            } else
            {
                this.changeTopBar(true);
            }
        }
    },

    openTopBar: function (show)
    {
        var container = $(this.projectContainer);
        var fixedHeader = $(this.fixedHeader, container);
        var headerBtn = $(this.headerBtnMobile);

        if (show)
        {
            fixedHeader.stop(true).animate({
                top: 0,
                opacity: 1
            }, 200, 'easeOutExpo');

            headerBtn.addClass('hidden');

            this.projectOpen = true;
        }
        else
        {
            fixedHeader.stop(true).animate({
                top: -80,
                opacity: 0
            }, 800, 'easeOutExpo');

            headerBtn.removeClass('hidden');

            this.projectOpen = false;
        }
    },

    changeTopBar: function (show)
    {
        var container = $(this.projectContainer);
        var fixedHeader = $(this.fixedHeader, container);
        var projectName = $(this.projectName, fixedHeader);

        if (show)
        {
            $('i', fixedHeader).css({
                color: this.closeBtnBlackColor
            });

            projectName.stop(true).animate({
                marginTop: 0,
                opacity: 1
            }, 800, 'easeOutExpo');

            fixedHeader.addClass('active');

            this.closeBtnBlack = true;
        }
        else
        {
            $('i', fixedHeader).css({
                color: '#fff'
            });

            projectName.stop(true).animate({
                marginTop: -80,
                opacity: 0
            }, 800, 'easeOutExpo');

            fixedHeader.removeClass('active');

            this.closeBtnBlack = false;
        }
    }

};
