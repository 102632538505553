var loader = {

    showSpeed: 200,
    hideSpeed: 500,

    show: function ()
    {
        $('<div />', {
            id: 'loader-wrapper',
            html: '<div id="loader"><div class="dot1"></div><div class="dot2"></div></div>'
        }).fadeIn(this.showSpeed).appendTo('body');
    },

    hide: function ()
    {
        $('#loader-wrapper').fadeOut(this.hideSpeed);

        setTimeout(function () {
            $('#loader-wrapper').remove();
        }, this.hideSpeed);
    }

};